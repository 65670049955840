import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import oderNowImg from "../Assets/images/offer.jpeg";
import nonVegBagarasImg from "../Assets/images/nonVegBagara.png";
import vegBagarasImg from "../Assets/images/vegBagara.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const Offers = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div>
      <Card sx={{ marginBottom: "2em" }}>
        <div style={{ position: "relative", background: "black" }}>
          <CardMedia
            style={{
              height: "250px",
              paddingTop: "2%",
              opacity: 0.5,
              background: "rgba(0, 0, 0, 0.5)",
            }}
            component="img"
            image={oderNowImg}
            title="order-now"
            alt="Offers"
          />
          <Typography
            variant="h2"
            sx={{
              position: "absolute",
              color: "white",
              top: "2em",
              left: "50%",
              fontWeight: "bold",
              fontFamily: "Sansita Swashed",
              transform: "translateX(-50%)",
            }}
          >
            Offers
          </Typography>
        </div>
      </Card>
      <Stack
        direction={matches ? "row" : "column"}
        spacing={5}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="top"
          data-testid="dashboard-loading"
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardContent sx={{ background: "orange" }}>
              <Typography
                variant="body2"
                color="black"
                sx={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "0.1em",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                <strong>Discount Code</strong>
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "0.5em",
                  fontWeight: "bold",
                  fontStyle: "normal",
                }}
              >
                BAGARA50NV
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={nonVegBagarasImg}
              title="nonveg-bagara"
            />

            <CardContent
              sx={{
                justifyContent: "center",
                alignContent: "center",
                fontStyle: "normal",
                background: "lightgrey",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  minHeight: "5em",
                }}
                gutterBottom
                variant="h6"
                component="div"
                color="#20141e"
              >
                Buy 1 Telangana Mutton Curry with Bagara get 1 Andhra Chicken
                Curry with Bagara 50% off
              </Typography>
            </CardContent>
            <CardContent sx={{ background: "cream" }}>
              <Typography
                variant="body2"
                color="#20141e"
                sx={{ textAlign: "justify" }}
              >
                ** Offer valid only on Andhra Chicken Curry with bagara,
                Telangana mutton curry with Bagara. Offer is not valid for any
                other menu items. While supplies last. Cannot be combined with
                any other offers or promotions.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="top"
          data-testid="dashboard-loading"
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardContent sx={{ background: "orange" }}>
              <Typography
                variant="body2"
                color="black"
                sx={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "0.1em",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                <strong>Discount Code</strong>
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "0.5em",
                  fontWeight: "bold",
                  fontStyle: "normal",
                }}
              >
                BAGARA50VEG
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={vegBagarasImg}
              title="veg-bagara"
            />

            <CardContent
              sx={{
                justifyContent: "center",
                alignContent: "center",
                fontStyle: "normal",
                background: "lightgrey",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  minHeight: "5em",
                }}
                gutterBottom
                variant="h6"
                component="div"
                color="#20141e"
              >
                Buy 1 Mixed Vegetable Bagara get 1 Guthivankaya Bagara 50% off
              </Typography>
            </CardContent>
            <CardContent sx={{ background: "cream" }}>
              <Typography
                variant="body2"
                color="#20141e"
                sx={{ textAlign: "justify" }}
              >
                ** Offer valid only on Mixed Vegetable bagara, Guthivankay
                Bagara. Offer is not valid for any other menu items. While
                supplies last. Cannot be combined with any other offers or
                promotions.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </div>
  );
};

export default Offers;
