const veg_appetizers: Array<string> = [
    "Onion pakora",
    "Mirchi Bajji",
    "Onion stuffed bajji",
    "Punugulu",
    "Andhra gare",
    "Masala vada",
    "Gobi-65",
    "Chilli paneer",
    "Samosa",
    "Cocktail samosa",
    "Gobi manchurian",
    "Veg manchurian",
    "Crispy baby corn",
    "Baby corn Manchurian",
    "Onion Rings",
    "Masala Fries",
    "Cut mirchi",
    "Beerakaya bajji",
    "Aloo bajji",
    "Dahi vada",
    "Puranalu(sweet)",
    "Mysore bonda",
    "Indian cheese tacos",
    "Veg Frankie"
];

const non_veg_appetizers: Array<string> = [
    "Kheema masala vada",
    "Apollo chicken",
    "Apollo Fish",
    "Apollo Shrimp",
    "Chilli Shrimp",
    "Chilli egg",
    "Shrimp Manchurian",
    "Shrimp Pakoda",
    "Crispy shrimp",
    "Chicken Pakoda",
    "Fish Pakoda",
    "Seasame chicken",
    "Crispy Goat chops",
    "Mamsam Roast(Goat fry)",
    "Rajolu Royyala Vepudu(Shrimp fry)",
    "Chilli chicken",
    "Chicken-65",
    "Chicken Taka Tak",
    "Chicken Majestic",
    "Mirapakai kodi",
    "Chicken fingers",
    "Konaseema kodi vepudu",
    "Penam kodi",
    "Chicken Roast",
    "Fish Daniya",
    "Chicken Lollipop",
    "Chicken wings(dry/wet)",
    "Daba Chicken",
    "Sambal Chicken",
    "Whole Fish",
    "Fish fry",
    "Liver fry(chicken)",
    "Goat kidney fry",
    "Kamsu pitta fry"
];

const veg_entries: Array<string> = [
    "Palak Paneer",
    "Methi Chaman",
    "Paneer tikka masala",
    "Navarathan koorma",
    "Aloo Koorma",
    "Kadai Paneer",
    "Mixed vegetables(dry)",
    "Dal Makhani",
    "Tomato pappu",
    "Dosakai pappu",
    "Mango papu",
    "Gongura papu",
    "Amla papu",
    "Spinach papu",
    "Guttivankaya curry",
    "Guttivankaya masala",
    "Sorakai kura",
    "Sorakai papu kura",
    "Beerakai kura",
    "Beerakai senagapappu kura",
    "Mulakkai kura",
    "Mulakkai tomato kura",
    "Mulakkai senagapappu kura",
    "Chikkudukai kura",
    "Aloo fry",
    "Aloo soya kurma",
    "Aloo kurma",
    "Bendakai Masala",
    "Bendakai kura",
    "Bendakai fry",
    "Dondakai fry",
    "Cabbage 65(fry)",
    "Capsicum curry",
    "Carrot fry",
    "Aloo spinach fry",
    "Jeedipappu kura(Cashewnut curry)",
    "Majjiga charu",
    "puttakokkula kura(Mushroom)",
    "Potlakai fry",
    "Cabbage fry",
    "Cauliflower curry",
    "Tomato curry"
];

const non_veg_entries: Array<string> = [
    "Butter chicken",
    "Chicken tikka masala",
    "Palak chicken",
    "Andhra chicken curry",
    "Chicken chettinad",
    "Avakai chicken curry",
    "Gongura chicken curry",
    "Chicken fry",
    "Chicken vindaloo",
    "Chicken koorma",
    "Notu kodi kura",
    "Gongura goat curry",
    "Goat curry",
    "Mulakkai mutton",
    "Dosakai mutton",
    "Chepala pulusu(fish curry)",
    "Royyala pulsu(shrimp curry)",
    "Egg Masala curry",
    "Egg fry"
];

const pulavs: Array<string> = [
    "Veg pulav",
    "Tomato pulav",
    "Gongura pulav",
    "Coconut pulav",
    "Pudina pulav",
    "Egg pulav",
    "Nethi kodi pulav",
    "Chicken pulav",
    "Veta pulav(goat)",
    "Gongura chicken pulav",
    "Gongura Goat pulav",
    "Triple pulav(chicken+goat+shrimp)",
    "Natu kodi pulav",
    "Kunda chicken pulav",
    "Kunda goat pulav",
    "Kunda veg pulav",
    "Kunda egg pulav"
];

const biryanis: Array<string> = [
    "Veg dum biryani",
    "Paneer biryani",
    "Egg biryani",
    "Chicken dum biryani",
    "Chicken joint biryani",
    "Gongura chicken biryani",
    "Kheema biryani",
    "Thalapakattu biryani",
    "Goat dum biryani",
    "Gongura goat biryani",
    "Chicken cheese biryani",
    "Chicken kunda biryani",
    "Vijayawada chicken biryani",
    "Vijayawada shrimp biryani",
    "Vijayawada fish biryani",
    "Vijayawada egg biryani"
];

const rice_noddles: Array<string> = [
    "Plain rice",
    "Pulihora",
    "Lemon rice",
    "Jeera Rice",
    "Veg fried rice",
    "Egg fried rice",
    "Chicken fried rice",
    "Mixed fried rice(veggies+chicken+egg+shrimp)",
    "Veg Noodles",
    "Egg Noodles",
    "Chicken Noodles",
    "Mixed Noodles(veggies+chicken+egg+shrimp)"
];

const breads: Array<string> = [
    "Chapati",
    "Roti",
    "Naan",
    "Butter naan",
    "Garlic naan",
    "Onion naan",
    "Aloo kulcha",
    "Palak kulcha",
    "Keema kulcha"
];

const desserts: Array<string> = [
    "Sheer koorma",
    "Shahi ka tukada",
    "Double ka meeta",
    "Bread ka baat",
    "Chakra pongal",
    "Sweet pongal",
    "Rice kheer",
    "Semya kheer",
    "Beetroot halwa",
    "Carrot halwa",
    "Gulab jamun",
    "Junnu",
    "Fresh Fruit custard",
    "Jamun pudding"
];

const live_chat_counter: Array<string> = [
    "Pani puri",
    "Masala puri",
    "Sev puri",
    "Dahi puri",
    "Samosa chat",
    "Aloo chat",
    "Papdi chat",
    "Pav bhaji",
    "Batani masala",
    "Egg batani masala",
    "Tomato slice masala",
    "Bajji masala",
    "Aloo masala"
];

const tiffins_onsite: Array<string> = [
    "Plain dosa",
    "Onion dosa",
    "kara dosa",
    "Karvepaku karam dosa",
    "Kobbari karam dosa",
    "Nalla karam dosa",
    "Podi dosa",
    "Set dosa",
    "Paneer dosa",
    "Cheese dosa",
    "Ghee dosa",
    "Egg dosa",
    "Masala dosa",
    "Mysore masala dosa",
    "Kheema dosa",
    "Chicken tikka dosa",
    "Tomato dosa",
    "Chicken tikka dosa",
    "Carrot dosa",
    "Beetroot dosa",
    "Noodle dosa",
    "Upma dosa",
    "Baahubali dosa",
    "Choclate dosa",
    "Siracha dosa",
    "Mixed fruit jam dosa",
    "Pulihora dosa",
    "Corn dosa",
    "Mixed veggie dosa",
    "Cone dosa",
    "Egg mughlai dosa",
    "Chicken mughlai dosa",
    "Pav bhaji dosa",
    "Dry fruit dosa",
    "Pizza dosa",
    "Chaat dosa",
    "Dosa with chicken curry",
    "Raagi dosa",
    "Chicken tikka dosa",
    "Oats dosa",
    "Buttermilk dosa",
    "Palak dosa",
    "Palak paneer dosa",
    "Onion chilli pesarattu",
    "Upma pesarattu",
    "Onion Uthappam",
    "Onion tomato chilli uthappam"
];

const idli: Array<string> = [
    "Plain idli",
    "Ghee idli",
    "Podi idli",
    "Karvepaku kaaram idli",
    "Kobbari kaaram idli",
    "Nalla kaaram idli",
    "Babai hotel spcl idli",
    "Sambar idli",
    "Gunta punugulu"
];

const breads_onsite: Array<string> = [
    "Roti/Chapathi+Green peas Masala",
    "Roti/chapathi+Egg Masala",
    "Roti/chapathi+Chicken curry",
    "Chicken dum biryani",
    "Roti/chapathi+Goat curry",
    "Poori+Onion curry"
];
const onsite_non_veg_appetizers: Array<string> = [
    "Goat roast",
    "Goat chops",
    "Kodi vepudu",
    "Andhra fish fry"
];

export {
    veg_appetizers,
    non_veg_appetizers,
    veg_entries,
    non_veg_entries,
    pulavs,
    biryanis,
    rice_noddles,
    breads,
    desserts,
    live_chat_counter,
    tiffins_onsite,
    idli,
    breads_onsite,
    onsite_non_veg_appetizers
};