// Assuming MenuItem is defined in a file named 'types.ts' in the same directory
import { MenuItem } from "./types";

const veg_appetizers: MenuItem[] = [
  {
    name: "Gobi 65",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Cut Mirchi",
    price: 6.99,
    isAvailable: true,
  },
  {
    name: "Kaaram Samosa",
    price: 6.99,
    isAvailable: true,
  },

  {
    name: "Mushroom Fry",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Pepper Baby Corn",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Masala Mirchi Bajji",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Chili Paneer",
    price: 10.99,
    isAvailable: true,
  },
  {
    name: "Boiled Peanut Masala",
    price: 6.99,
    isAvailable: false,
  },
];

const non_veg_appetizers: MenuItem[] = [
  {
    name: "Anda Masala",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Kaju Chicken Pakoda",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Chicken 555",
    price: 12.99,
    isAvailable: true,
  },
  {
    name: "Karvepaku Chicken Roast",
    price: 12.99,
    isAvailable: true,
  },
  {
    name: "Mutton Ghee Roast",
    price: 16.99,
    isAvailable: true,
  },

  {
    name: "Boneless Fish Pakoda",
    price: 12.99,
    isAvailable: true,
  },
  {
    name: "Shrimp 65",
    price: 13.99,
    isAvailable: false,
  },
  {
    name: "Ring Cut Fish Fry(Bone-in)",
    price: 12.99,
    isAvailable: false,
  },
];

const bagaras: MenuItem[] = [
  {
    name: "Guthivankaya Bagara",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Mixed Vegetable Bagara",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Egg Roast Bagara",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Chicken Fry piece Bagara",
    price: 14.99,
    isAvailable: true,
  },
  {
    name: "Chicken Ghee Roast Bagara",
    price: 14.99,
    isAvailable: true,
  },
  {
    name: "Mutton Fry piece Bagara",
    price: 15.99,
    isAvailable: true,
  },

  {
    name: "Andhra Chicken Curry with Bagara",
    price: 14.99,
    isAvailable: true,
  },
  {
    name: "Telangana Mutton Curry with Bagara",
    price: 16.99,
    isAvailable: true,
  },
  {
    name: "Shrimp Fry piece Bagara",
    price: 16.99,
    isAvailable: false,
  },
];

const biryanis: MenuItem[] = [
  {
    name: "Hyd Chicken Dum Biryani",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Guthivankaya Biryani",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Mixed Vegetable Biryani",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Egg Roast Biryani",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Bagara's Special Chicken Biryani",
    price: 14.99,
    isAvailable: true,
  },
  {
    name: "Bagara's Special Paneer Biryani",
    price: 11.99,
    isAvailable: true,
  },
  {
    name: "Meghana Chicken Fry Piece Biryani",
    price: 14.99,
    isAvailable: true,
  },

  {
    name: "Mutton Ghee Roast Biryani",
    price: 15.99,
    isAvailable: true,
  },
  {
    name: "Shrimp Biryani",
    price: 15.99,
    isAvailable: false,
  },
];

const speciality_rice: MenuItem[] = [
  {
    name: "Jeera Rice",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Mint Rice",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Gongura Rice",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Mudha Pappu Avakaya Annam",
    price: 9.99,
    isAvailable: true,
  },

  {
    name: "Sambar Rice",
    price: 8.99,
    isAvailable: true,
  },
  {
    name: "Sambar Rice with Chicken Fry",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Curd Rice",
    price: 9.99,
    isAvailable: false,
  },
  {
    name: "Cut Mango Pickel Rice",
    price: 9.99,
    isAvailable: false,
  },
];

const breafast_items: MenuItem[] = [
  {
    name: "Poori With Chicken Curry",
    price: 10.99,
    isAvailable: true,
  },
  {
    name: "Poori With Mutton Curry",
    price: 12.99,
    isAvailable: true,
  },
  {
    name: "Roti With Chicken Curry",
    price: 10.99,
    isAvailable: true,
  },
  {
    name: "Roti With Mutton Curry",
    price: 12.99,
    isAvailable: true,
  },
  {
    name: "Poori With Mixed Vegetable Curry",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Poori With Guthivankaya Curry",
    price: 8.99,
    isAvailable: true,
  },
  {
    name: "Roti With Mixed Vegetable Curry",
    price: 9.99,
    isAvailable: true,
  },
  {
    name: "Roti With Guthivankaya Curry",
    price: 8.99,
    isAvailable: true,
  },
];

const desserts: MenuItem[] = [
  {
    name: "Shahi Ka Tukda",
    price: 5.99,
    isAvailable: true,
  },
  {
    name: "Royal Falooda",
    price: 7.99,
    isAvailable: false,
  },
  {
    name: "Gulab Jamun",
    price: 4.99,
    isAvailable: false,
  },
];

const drinks: MenuItem[] = [
  {
    name: "Mango Lassi",
    price: 3.99,
    isAvailable: true,
  },
  {
    name: "Sapota Juice",
    price: 4.99,
    isAvailable: false,
  },
  {
    name: "Sweet Lime Soda",
    price: 4.99,
    isAvailable: false,
  },
  {
    name: "Salt Lime Soda",
    price: 4.99,
    isAvailable: false,
  },
  {
    name: "Jeera Masala Soda",
    price: 4.99,
    isAvailable: false,
  },
  {
    name: "Badam Milk",
    price: 4.99,
    isAvailable: false,
  },
  {
    name: "Sweet Lassi",
    price: 3.99,
    isAvailable: false,
  },
  {
    name: "Masala Chaas",
    price: 3.99,
    isAvailable: false,
  },

  {
    name: "Fanta",
    price: 1.99,
    isAvailable: false,
  },
  {
    name: "Water Bottle",
    price: 1.99,
    isAvailable: true,
  },
];

const bagaras_thali: Array<string> = [
  "Gobi-65",
  "Guthivankaya Curry",
  "Button Mushrrom",
  "Bagara Rice",
  "Biryani Rice",
  "Dessert",
  "Soft Drink",
];

const bagaras_non_veg_thali: Array<string> = [
  "Kaju Chicken Pakoda",
  "Chicken Fry Piece",
  "Shrimp Fry",
  "Andhra Chicken Curry",
  "Telangana Mutton Curry",
  "Bagara Rice",
  "Biryani Rice",
  "Dessert",
  "Soft Drink",
];

const speciality_rice_thali: Array<string> = [
  "Jeera Rice",
  "Mint Rice",
  "Gongura Rice",
  "Sambar Rice",
  "Curd Rice",
  "Papad",
  "Lemon Pickel",
];

const thali: MenuItem[] = [
  {
    name: "Bagara Veg Thali",
    price: 13.99,
    isAvailable: false,
  },
  {
    name: "Bagara Non-Veg Thali",
    price: 16.99,
    isAvailable: false,
  },
  {
    name: "Speciality Rice Thali",
    price: 14.99,
    isAvailable: false,
  },
];

const friday_special: MenuItem[] = [
  {
    name: "Ghee Mutton Biryani",
    price: 14.99,
    isAvailable: false,
  },
  {
    name: "Haleem-Small",
    price: 6.99,
    isAvailable: false,
  },
  {
    name: "Haleem-Medium",
    price: 13.99,
    isAvailable: false,
  },
  {
    name: "Haleem-Large",
    price: 19.99,
    isAvailable: false,
  },
];

const saturday_special: MenuItem[] = [
  {
    name: "Raju Gari Kodi Pulav",
    price: 11.99,
    isAvailable: false,
  },
  {
    name: "Mutton Kheema Biryani",
    price: 15.99,
    isAvailable: false,
  },
];

const sunday_special: MenuItem[] = [
  {
    name: "Nellore Chepala Pulusu",
    price: 15.99,
    isAvailable: false,
  },
  {
    name: "Doopudu Pothu Biryani(Mutton)",
    price: 15.99,
    isAvailable: false,
  },
];

export {
  veg_appetizers,
  non_veg_appetizers,
  bagaras,
  biryanis,
  speciality_rice,
  breafast_items,
  desserts,
  drinks,
  bagaras_thali,
  bagaras_non_veg_thali,
  speciality_rice_thali,
  friday_special,
  saturday_special,
  sunday_special,
  thali,
};
